import * as React from "react";
import styled from "styled-components";
import arrow from "../../images/arrow.svg";
import arrowActive from "../../images/arrow-active.svg";
import img1 from "../../images/main5-1.png";
import img2 from "../../images/main5-2.png";
import img3 from "../../images/main5-3.png";
import img4 from "../../images/main5-4.png";
import img5 from "../../images/main5-5.png";
import img6 from "../../images/main5-6.png";
import { Controller, Scene } from "react-scrollmagic";
import { Link } from "gatsby";

import { Tween } from "react-gsap";

const ContainerCss = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  white-space: pre-wrap;
  line-height: 1.2;
  overflow-x: hidden;

  .main {
    width: 81vw;
    display: flex;
    justify-content: space-between;
    height: fit-content !important;
    .main-title {
      font-family: "Pretendard-ExtraBold";
      font-size: 3.1vw; //60px
      display: flex;
        flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-Medium";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 3.6vw;
    }
  }

  .main__column {
    height: 100vh;
    width: max-content !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 !important;
  }

  .main5-img {
    width: 50%;
    height: fit-content;
    div {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    div:first-child {
      overflow: hidden;
      width: 40vw;
      height: 17vw;
      margin-left: 10vw;
    }
    div:nth-child(2) {
      margin: 2vw 0;
      width: 27.5vw;
      height: auto;
    }
    div:nth-child(4) {
      width: 30vw;
      margin: 2vw 0 2vw 20vw;
    }
    div:nth-child(5) {
      width: 35vw;
      margin: 2vw 0;
    }
    div:nth-child(6) {
      width: 37vw;
      margin: 2vw 5vw;
    }
  }

  .show-460 {
    display: none;
  }
  @media screen and (max-width: 1020px) {
    .main {
      .main-title {
        font-size: 4.6vw;
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
      }
      .main-sub {
        font-size: 2vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    justify-content: flex-start;
    .main__column {
      height: auto;
    }
    .show-460 {
      display: flex;
      margin: 30vw 5% 0vw;
      flex-direction: column;
      a {
        width: 181px;
        height: 48px;
        margin-bottom: 13.333vw;
      }
      .main-title {
        font-size: 7.5vw;
        display: flex;
        flex-direction: column;
      }
      .main-sub {
        font-size: 3.5vw;
        margin: 4vw 0 6vw;
      }
      .main5-img {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 200vw;
        margin-left: -12vw;
        div:first-child {
          width: 73vw;
          height: fit-content;
          margin: 0;
        }
        div:nth-child(2) {
          width: 53vw;
          margin: 0;
        }
        div:nth-child(3) {
          width: 73vw;
          margin: 0;
        }
      }
    }

    .hide-460 {
      display: none;
    }
  }
`;

export const GhostBtn = styled.button`
  position: relative;
  font-family: "Pretendard-Bold";
  padding: 1vw;
  padding-right: 3.8vw;
  font-size: 0.8vw; //16px
  border-radius: 0.2vw;
  border: 1.5px solid #000;
  display: flex;
  align-items: center;
  background-color: transparent;
  box-shadow: inset 0px 0px 0px #000;
  cursor: pointer;
  transition: box-shadow 0.5s ease-in-out;

  .icon-mask {
    position: absolute;
    overflow: hidden;
    right: 1vw;
    display: flex;
    width: 2.4vw;
    .icon {
      transition: transform 0.5s ease-in-out;
      :first-child {
        transform: translate3d(0%, 0, 0);
      }
      :last-child {
        transform: translate3d(-220%, 0, 0);
      }
    }
    object {
      width: 2.4vw;
    }
  }

  p {
    font-weight: "700";

    transition: color 0.5s ease-in-out;
    margin-right: 0.5vw;
    color: #222222;
  }
  :hover {
    p {
      color: #00e2d5;
    }
    box-shadow: inset 14vw 0px 0px #000;
    .icon {
      :first-child {
        transform: translate3d(100%, 0, 0);
      }
      :last-child {
        transform: translate3d(-100%, 0, 0);
      }
    }
  }
  @media screen and (max-width: 1020px) {
    padding: 1.6vw;
    padding-right: 5vw;
    font-size: 2vw;
    border-radius: 0.4vw;
    > div {
      top: 41% !important;
    }
  }
  @media screen and (max-width: 460px) {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    margin-left: 0;
    font-size: 2.5vw;
    font-size: 3vw;
    padding: 3vw;
    padding-right: 15vw;
    :active {
      p {
        color: #00e2d5;
      }
      box-shadow: inset 200vw 0px 0px #000;
      .icon {
        :first-child {
          transform: translate3d(100%, 0, 0);
        }
        :last-child {
          transform: translate3d(-100%, 0, 0);
        }
      }
    }
    .icon-mask {
      width: 10vw;
      object {
        width: 8vw;
      }
    }
  }
`;
function Main5({ isDeviceVer }) {
  const sub = `오피스와 학습공간, 주거공간까지\n공간에 대한 모든 서비스를 준비합니다.`;
  // const animation = {
  //   0: useScrollFadeIn("up", 1, 0),
  //   1: useScrollFadeIn("up", 1, 0.2),
  //   2: useScrollFadeIn("up", 1, 0.4),
  // };
  let innerh;
  let innerw;

  if (typeof window !== `undefined`) {
    innerh = window.innerHeight;
    innerw = window.innerWidth;
  }

  const vh = (coef) => innerh * (coef / 100);
  const vw = (coef) => innerw * (coef / 100);
  return (
    <ContainerCss>
      <div className="main hide-460">
        <Controller>
          <Scene
            duration={isDeviceVer === 1 ? "30%" : isDeviceVer === 2 ? "80%" : "150%"}
            triggerHook={0}
            pin
          >
            <div className="main__column">
              <div className="main-title">
                <div className="text-mask">
                  <span>만남과 나눔으로</span>
                </div>
                <div className="text-mask">
                  <span>{`더 가치있는 \n공간디자인`}</span>
                </div>
              </div>
              <p className="main-sub">{sub}</p>
              <Link to="/spacebusiness">
                <GhostBtn>
                  <p>Space Business</p>
                  <div className="icon-mask">
                    <object className="icon" data={arrow} />
                    <object className="icon" data={arrowActive} />
                  </div>
                </GhostBtn>
              </Link>
            </div>
          </Scene>
        </Controller>

        <div className="main5-img">
          <div>
            <img src={img1} alt='img46'/>
          </div>
          <div>
            <img src={img2} alt='img47'/>
          </div>
          <div>
            <img src={img3} alt='img48'/>
          </div>
          <div>
            <img src={img4} alt='img49'/>
          </div>
          <div>
            <img src={img5} alt='img50'/>
          </div>
          <div>
            <img src={img6} alt='img51'/>
          </div>
        </div>
      </div>
      <div className="show-460">
        <div className="main__column">
          <div className="main-title">
            <div className="text-mask">
              <span>만남과 나눔으로</span>
            </div>
            <div className="text-mask">
              <span>더 가치있는 공간디자인</span>
            </div>
          </div>
          <p className="main-sub">{sub}</p>
          <Link to="/spacebusiness">
            <GhostBtn>
              <p>Space Business</p>
              <div style={{ top: "39%" }} className="icon-mask">
                <object className="icon" data={arrow} />
                <object className="icon" data={arrowActive} />
              </div>
            </GhostBtn>
          </Link>
        </div>
        <Tween
          to={{
            x: "-" + vw(90),
            scrollTrigger: {
              start: vw(720) + " center",
              end: vw(770) + " center",
              scrub: 0.5,
              // markers: true,
            },
          }}
        >
          <div className="main5-img">
            <div>
              <img src={img1} alt='img52'/>
            </div>
            <div>
              <img src={img2} alt='img53'/>
            </div>
            <div>
              <img src={img3} alt='img54'/>
            </div>
          </div>
        </Tween>
      </div>
    </ContainerCss>
  );
}

export default Main5;
